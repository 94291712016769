<template>
  <div id="main-wrapper" v-bind:class="[isActive ? 'mini-sidebar' : '']">
    <router-view/>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isActive: false
      }
    },
    created() {
        this.$root.$refs.App = this;
    },
    computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
    methods: {
      toggleNavMenuMain: function() {
        this.isActive = !this.isActive;
      }
    },
  }
</script>