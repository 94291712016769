import Vue from 'vue'
import App from './App.vue'
import router from './router'
import config from './config'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/style.css'

import store from './store'
import Axios from 'axios'

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.config.productionTip = false
Vue.config.API_PATH = config.API_PATH

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')