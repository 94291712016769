import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    items: {},
    inward_items: {}
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token, user){
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
    set_items(state, items) {
        state.items = items
    },
    set_inward_items(state, inward_items) {
        state.inward_items = inward_items
    }
  },
  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({url: Vue.config.API_PATH+'users/login', data: user, method: 'POST' })
        .then(resp => {
          let dataObj = resp.data;
          const token = dataObj.data.access_token;
          const user = dataObj.data.user;
          localStorage.setItem('token', token);
          axios.defaults.headers.common['Authorization'] = token;
          commit('auth_success', token, user);
          resolve(resp);
        })
        .catch(err => {
          commit('auth_error');
          localStorage.removeItem('token');
          reject(err);
        })
      })
    },
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    items({commit}){
      return new Promise((resolve, reject) => {
        commit('set_items');
        axios({url: Vue.config.API_PATH + 'items', method: 'GET' })
        .then(resp => {
          let dataObj = resp.data;          
          let items = dataObj.data.data;
          commit('set_items', items)
          resolve(resp);
        })
        .catch(err => {          
          reject(err);
        })
      })
    },
    inward_items({commit}){
      return new Promise((resolve, reject) => {
        commit('set_items');
        axios({url: Vue.config.API_PATH + 'inward', method: 'GET' })
        .then(resp => {
          let dataObj = resp.data;          
          let inward_items = dataObj.data.data;          
          commit('set_inward_items', inward_items)
          resolve(resp);
        })
        .catch(err => {          
          reject(err);
        })
      })
    },
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    get_items: state => state.items,
    get_inward_items: state => state.inward_items
  }
})